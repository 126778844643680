import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { Blanket, BlanketContent, BlanketTitle } from '../../components/Blanket'
import { Container } from '../../components/Container'
import { NewsFeed } from '../../components/NewsFeed'
import { SEO } from '../../components/Seo'

type AcademyCupPageProps = {
  data: any
}

export default function AcademyCupPage({ data }: AcademyCupPageProps) {
  const featuredImage = data.cover?.localFile?.childImageSharp?.fluid

  return (
    <Blanket dark={false} noPadding>
      <SEO title={'Academy Cup'} />

      <Container>
        <BlanketTitle>{'Academy Cup'}</BlanketTitle>

        <BlanketContent>
          <Row>
            <Col
              xs={12}
              md={{ offset: 3, span: 6 }}
              style={{ marginBottom: 48 }}
            >
              <GatsbyImage fluid={featuredImage} />
            </Col>

            <Col xs={12}>
              <NewsFeed category={'academyCup'} />
            </Col>
          </Row>
        </BlanketContent>
      </Container>
    </Blanket>
  )
}

export const query = graphql`
  query AcademyCupPage {
    cover: wordpressWpMedia(title: { eq: "PRA_CUP_2024_Locandina" }) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
